import Button from "components/Button";
import Modal from "..";
import styles from "./index.module.css";

const ActionsModal = ({
  children,
  isLoading = false,
  onClose,
  onSubmit,
  showModal,
  isSingleAction = false,
  stackLevel,
  submitButtonText = "Submit",
  submitDisabled = false,
}) => {
  return (
    <Modal
      onClose={onClose}
      showModal={showModal}
      stackLevel={stackLevel}
    >
      <div className={styles.container}>
        {children}
        <div className={styles.actions}>
          {!isSingleAction && <Button style="ghost_light" onClick={onClose}>Cancel</Button>}
          <Button
            onClick={onSubmit}
            style="fill_dark"
            disabled={submitDisabled || isLoading}
          >
            {isLoading ? "Loading..." : submitButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ActionsModal;
