import { createContext, useContext, useState } from "react";
import ConfirmationModal from "components/Modal/Confirmation";
import { useLoader as useFullscreenLoader } from "./loader";

const ConfirmContext = createContext();
export const useConfirm = () => useContext(ConfirmContext);

const ConfirmContextProvider = ({ children }) => {
  const [config, setConfig] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { showLoader, hideLoader } = useFullscreenLoader();

  const { confirmButtonText, onConfirm, prompt, useLoader } = config;

  const handleClose = () => {
    setIsModalOpen(false);
    setConfig({});
  };

  const handleConfirm = async () => {
    if(useLoader) {
      setIsModalOpen(false);
      showLoader();
      await onConfirm();
      setConfig({});
      hideLoader();
    } else {
      setIsLoading(true);
      await onConfirm();
      handleClose();
      setIsLoading(false);
    }
  };

  const handleSetConfirm = (confirmConfig) => {
    setConfig(confirmConfig);
    setIsModalOpen(true);
  };

  return (
    <ConfirmContext.Provider
      value={{ setConfirm: handleSetConfirm }}
    >
      {children}
      <ConfirmationModal
        confirmButtonText={confirmButtonText}
        isLoading={isLoading}
        onClose={handleClose}
        onConfirm={handleConfirm}
        prompt={prompt}
        showModal={isModalOpen}
      />
    </ConfirmContext.Provider>
  );
};

export default ConfirmContextProvider;
