import ActionsModal from "../Actions";
import styles from "./index.module.css";

const ConfirmationModal = ({
  confirmButtonText = "Confirm",
  isLoading,
  onClose,
  onConfirm,
  prompt,
  showModal
}) => {
  return (
    <ActionsModal
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={onConfirm}
      showModal={showModal}
      stackLevel="120"
      submitButtonText={confirmButtonText}
    >
      <div className={styles.text}>{prompt}</div>
    </ActionsModal>
  );
};

export default ConfirmationModal;
