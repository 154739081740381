import { UserProvider } from "@auth0/nextjs-auth0/client";
import { Inter } from "@next/font/google";
import { Provider as RollbarProvider, ErrorBoundary } from "@rollbar/react";
import AlertContextProvider from "contexts/alert";
import ConfirmContextProvider from "contexts/confirm";
import DangerConfirmContextProvider from "contexts/dangerConfirm";
import FormModalContextProvider from "contexts/formModal";
import LoaderContextProvider from "contexts/loader";
import SessionContextProvider from "contexts/session";
import UserContextProvider from "contexts/user";
import "styles/reset.css";
import "styles/globals.css";

const inter = Inter({ subsets: ["latin"] });

const rollbarConfig = {
  accessToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.NODE_ENV,
};

export default function App({ Component, pageProps }) {
  return (
    <main className={inter.className}>
      <RollbarProvider config={rollbarConfig}>
        <ErrorBoundary>
          <UserProvider>
            <UserContextProvider>
              <SessionContextProvider>
                <LoaderContextProvider>
                  <AlertContextProvider>
                    <DangerConfirmContextProvider>
                      <ConfirmContextProvider>
                        <FormModalContextProvider>
                          <Component {...pageProps} />
                        </FormModalContextProvider>
                      </ConfirmContextProvider>
                    </DangerConfirmContextProvider>
                  </AlertContextProvider>
                </LoaderContextProvider>
              </SessionContextProvider>
            </UserContextProvider>
          </UserProvider>
        </ErrorBoundary>
      </RollbarProvider>
    </main>
  );
}
