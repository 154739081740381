import clsx from "clsx";
import { forwardRef, Fragment } from "react";
import Label from "./Label";
import styles from "./styles.module.css";

const InputGroup = ({
  field,
  isReadonly,
  onChange,
  renderFormField,
  useSpacing,
  useStickyHeader,
  values = {}, // default value needed to prevent "renderFormField" from looking for group subfield values in wrong location
}, groupRef) => {
  const {
    displayColumns = 2,
    fields: fieldsInGroup,
    label,
    showGroupWrapper,
  } = field;

  const handleChange = (childField, value) => {
    onChange(field, { ...values, [childField.key]: value });
  };

  return (
    <div
      className={clsx(styles.groupWrapper, {
        [styles.groupSpacing]: !!useSpacing,
        [styles.groupWrapperFrame]: !!showGroupWrapper,
      })}
      ref={groupRef}
    >
      <Label
        isGroupLabel
        isReadonly={isReadonly}
        useStickyHeader={useStickyHeader}
      >
        {label}
      </Label>
      <div className={clsx(styles.groupFields, {
        [styles.readonlyColumns]: isReadonly && displayColumns >= 2,
        [styles.columns3]: displayColumns === 3
      })}>
        {fieldsInGroup.map((fieldInGroup) => {
          return (
            <Fragment key={fieldInGroup.key}>
              {renderFormField({
                field: fieldInGroup,
                isReadonly: fieldInGroup.isReadonly,
                state: values,
                onChange: handleChange,
              })}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default forwardRef(InputGroup);
