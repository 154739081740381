import clsx from "clsx";
import { Fragment, useEffect } from "react";
import { FIELD_TYPES } from "lib/consts";
import { getIsInputUnanswered } from "utils/input";
import Label from "./Label";
import styles from "./styles.module.css";

const Dropdown = ({
  field = {},
  indicateUnanswered,
  isReadonly,
  onChange,
  renderFormField,
  state,
  useSpacing,
  value,
}) => {
  const inputValue = value ?? "";

  const {
    hasVariableOptions,
    label,
    noPlaceholder,
    options,
    otherKey,
    otherLabel,
    placeholder,
    required,
  } = field;

  const inputOptions = [...options];
  if(otherKey) {
    inputOptions.push({
      value: "other",
      label: "Other",
      details: [{
        type: FIELD_TYPES.TEXT,
        key: otherKey,
        label: otherLabel || "Other:",
      }],
    });
  }

  const handleChange = (evt) => {
    onChange(field, evt.currentTarget.value);
  };

  if(!noPlaceholder) {
    inputOptions.unshift({ label: placeholder ?? "Choose..." });
  }

  const selectedOption = inputOptions.find(option => {
    let optionValue = option.value ?? "";
    if(typeof optionValue === "number" && typeof inputValue === "string") {
      optionValue = `${optionValue}`;
    }
    return optionValue === inputValue;
  });

  const isUnanswered = getIsInputUnanswered(inputValue);

  useEffect(() => {
    // if options change and value is no longer found among the new options, then reset value
    // Explanation: This is needed when a parent input conditionally renders a dropdown input and the set of dropdown options changes depending on the parent input value. If the current dropdown value is not found in the new set of options, then we force a reset of the value.
    if(hasVariableOptions) {
      const haveOptionsLoaded = options?.some(o => !!(o.value || o.value === 0 || o.value === false));
      if(haveOptionsLoaded && !isUnanswered && !selectedOption) {
        onChange(field, undefined);
      }
    }
  }, [hasVariableOptions, options]);

  return (
    <>
      <div className={clsx({ [styles.fieldSpacing]: !!useSpacing })}>
        <Label
          indicateUnanswered={indicateUnanswered}
          isReadonly={isReadonly}
          isRequired={required}
          isUnanswered={isUnanswered}
        >
          {label}
        </Label>
        {isReadonly
          ? <div className={styles.readonlyValue}>{!isUnanswered ? selectedOption?.label : ""}</div>
          : <select
              onChange={handleChange}
              className={clsx(styles.input, styles.dropdown)}
              value={inputValue}
            >
              {inputOptions.map((option, index) => (
                <option key={index} value={option.value ?? ""}>
                  {option.label}
                </option>
              ))}
            </select>
        }
      </div>
      {selectedOption?.details?.map(field => {
        return (
          <Fragment key={field.key}>
            {renderFormField({
              field,
              state,
              onChange,
            })}
          </Fragment>
        );
      })}
    </>
  );
};

export default Dropdown;
