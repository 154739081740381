import { DOCTOR_TYPES } from "./consts";

export const getLabelFromDoctorSpecialty = (specialtyKey) => {
  const doctorTypeKey = Object.keys(DOCTOR_TYPES).find(k => DOCTOR_TYPES[k].key === specialtyKey);
  return DOCTOR_TYPES[doctorTypeKey]?.label;
};

export const getPracticeFromDoctorKey = (key) => {
  const doctorTypeKey = Object.keys(DOCTOR_TYPES).find(k => DOCTOR_TYPES[k].key === key);
  return DOCTOR_TYPES[doctorTypeKey]?.practice;
};

export const getShortLabelFromDoctorSpecialty = (specialtyKey) => {
  const doctorTypeKey = Object.keys(DOCTOR_TYPES).find(k => DOCTOR_TYPES[k].key === specialtyKey);
  return DOCTOR_TYPES[doctorTypeKey]?.labelShort;
};

export const getAllDoctorExamTypes = (doctor) => {
  return doctor?.specialties?.map(s => Object.values(DOCTOR_TYPES).find(type => type.key === s.examType) || {}) || [];
};

export const getAllDoctorPractices = (doctor) => {
  return doctor?.specialties?.map(s => {
    const specialtyConfig = Object.values(DOCTOR_TYPES).find(v => v.key === s.examType);
    return specialtyConfig?.practice;
  }) || [];
};
