import clsx from "clsx";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import Button from "components/Button";
import BodyParts from "components/Input/BodyParts";
import DateInput from "components/Input/Date";
import DateTimeInput from "components/Input/DateTime";
import Dropdown from "components/Input/Dropdown";
import DynamicYesNo from "components/Input/DynamicYesNo";
import File from "components/Input/File";
import InputGroup from "components/Input/InputGroup";
import MonthInput from "components/Input/Month";
import MonthYearInput from "components/Input/MonthYear";
import MultiselectButtons from "components/Input/MultiselectButtons";
import Radio from "components/Input/Radio";
import RepeatableGroups from "components/Input/RepeatableGroups";
import SignatureInput from "components/Input/Signature";
import Textarea from "components/Input/Textarea";
import TextInput from "components/Input/Text";
import TimeInput from "components/Input/Time";
import YearInput from "components/Input/Year";
import { useAlert } from "contexts/alert";
import { FIELD_TYPES } from "lib/consts";
import { getDefaults, getFlattenedRequiredFields, validateForm } from "utils/form";
import styles from "./index.module.css";

const Form = ({
  formConfig,
  indicateUnanswered,
  initialValues,
  isInline = false,
  isReadonly,
  labelCancel,
  labelSubmit = "Submit",
  onCancel,
  onSubmit,
  showAllPages,
  useNavigator = false,
}) => {
  const navigatorGroupRefs = useRef([]);

  const [values, setValues] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const [groupKeysWithDefaultsApplied, setGroupKeysWithDefaultsApplied] = useState({});
  const [activeUploads, setActiveUploads] = useState(0);
  const [isFormDataValid, setIsFormDataValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [navigatorGroups, setNavigatorGroups] = useState([]);

  const { setAlert } = useAlert();

  const { pages } = formConfig;

  const defaultValues = useMemo(() => getDefaults(formConfig), [formConfig]);
  const requiredFields = useMemo(() => getFlattenedRequiredFields(formConfig), [formConfig]);

  const groupFieldsWithDefaultValuesByPage = useMemo(() => (
    pages.map(page => page.fields.filter(f => f.type === FIELD_TYPES.GROUP && f.allowDefaults))
  ), [pages]);
  const groupFieldsWithDefaultValuesForCurrentPage = groupFieldsWithDefaultValuesByPage[pageIndex];

  const handleGoToGroup = (groupKey) => {
    const groupRef = navigatorGroupRefs.current.find(navGroupRef => navGroupRef.groupKey === groupKey);
    groupRef?.element?.scrollIntoView({ behavior: "smooth" });
  };

  const nextPage = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setPageIndex(pageIndex + 1);
  };

  const prevPage = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setPageIndex(pageIndex - 1);
  };

  const handleFatal = prompt => setAlert(prompt);

  useEffect(() => {
    if (initialValues) {
      setValues(initialValues);
    }
  }, [initialValues]);

  const renderFormField = ({ field, state, onChange = handleChange }) => {
    let value = state ? state[field.key] : values[field.key];
    const fieldProps = {
      field,
      indicateUnanswered,
      isReadonly: isReadonly || field.isReadonly,
      onChange,
      onFatal: handleFatal,
      renderFormField,
      useSpacing: true,
    };

    switch (field.type) {
      case FIELD_TYPES.GROUP:
        const otherGroupProps = {};
        if(useNavigator) {
          const navGroupIndex = navigatorGroups.findIndex(group => group.key === field.key);
          if(navGroupIndex > -1) {
            otherGroupProps.ref = (element) => {
              navigatorGroupRefs.current[navGroupIndex] = { groupKey: field.key, element };
            };
          }
        }
        return (
          <InputGroup
            values={value}
            {...fieldProps}
            useStickyHeader={useNavigator}
            {...otherGroupProps}
          />
        );
      case FIELD_TYPES.CHECKBOX_GROUP:
        return (
          <MultiselectButtons
            values={value ? { ...value } : {}}
            state={state}
            {...fieldProps}
          />
        );
      case FIELD_TYPES.BODY_PARTS:
        return (
          <BodyParts
            values={value ? { ...value } : {}}
            state={state}
            {...fieldProps}
          />
        );
      case FIELD_TYPES.RADIO:
        return (
          <Radio
            {...fieldProps}
            state={state}
            value={value}
          />
        );
      case FIELD_TYPES.DROPDOWN:
        return (
          <Dropdown
            {...fieldProps}
            value={value}
            state={state}
          />
        );
      case FIELD_TYPES.TEXT:
        return (
          <TextInput
            {...fieldProps}
            value={value ?? ""}
          />
        );
      case FIELD_TYPES.TEXTAREA:
        return (
          <Textarea
            {...fieldProps}
            value={value ?? ""}
          />
        );
      case FIELD_TYPES.DATE:
        return (
          <DateInput
            {...fieldProps}
            value={value ?? ""}
          />
        );
      case FIELD_TYPES.DATE_TIME:
        return (
          <DateTimeInput
            {...fieldProps}
            value={value ?? ""}
          />
        );
      case FIELD_TYPES.DYNAMIC_YESNO:
        return (
          <DynamicYesNo
            {...fieldProps}
            value={value}
            state={state}
          />
        );
      case FIELD_TYPES.REPEATABLE_GROUPS:
        return (
          <RepeatableGroups
            {...fieldProps}
            values={value ? [...value] : []}
          />
        );
      case FIELD_TYPES.MONTH:
        return (
          <MonthInput
            {...fieldProps}
            value={value ?? ""}
          />
        );
      case FIELD_TYPES.YEAR:
        return (
          <YearInput
            {...fieldProps}
            value={value ?? ""}
          />
        );
      case FIELD_TYPES.MONTH_YEAR:
        return (
          <MonthYearInput
            {...fieldProps}
            value={value}
          />
        );
      case FIELD_TYPES.TIME:
        return (
          <TimeInput
            {...fieldProps}
            value={value ?? ""}
          />
        );
      case FIELD_TYPES.FILE:
        return (
          <File
            {...fieldProps}
            onUploadEnd={() => setActiveUploads(u => u - 1)}
            onUploadStart={() => setActiveUploads(u => u + 1)}
            value={value}
          />
        );
      case FIELD_TYPES.SIGNATURE:
        return (
          <SignatureInput
            {...fieldProps}
            value={value}
          />
        );
      default:
        throw new Error(
          `Undefined field type ${field.type} for field ${field.label}`
        );
    }
  };

  const renderPage = (index) => {
    return pages[index].fields.map((field) => {
      return (
        <Fragment key={field.key}>
          {renderFormField({ field })}
        </Fragment>
      );
    });
  };

  const renderForm = () => {
    if(showAllPages) {
      return pages.map((page, pIndex) => {
        return (
          <Fragment key={`page-${pIndex}`}>
            {renderPage(pIndex)}
          </Fragment>
        );
      });
    } else {
      return renderPage(pageIndex);
    }
  }

  const handleChange = (field, value) => {
    // updating state without callback is OK since all uses of handleChange are instantaneous
    // even if callback method is used, updating data for group fields will still be a problem
    setValues({ ...values, [field.key]: value });
  };

  const applyDefaultsForGroup = (groupField) => {
    const groupKey = groupField.key;
    const groupValues = defaultValues[groupKey];
    handleChange(groupField, groupValues);
    setGroupKeysWithDefaultsApplied({
      ...groupKeysWithDefaultsApplied,
      [groupKey]: true
    });
  };

  const submitForm = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await onSubmit(values);
    } catch(e) {
      setIsSubmitting(false);
      if(e !== "form_validation_fail") {
        throw e;
      }
    }
  };

  useEffect(() => {
    if(requiredFields) {
      const isFormValid = validateForm({ flattenedRequiredFields: requiredFields, values });
      setIsFormDataValid(isFormValid);
    }
  }, [requiredFields, values]);

  useEffect(() => {
    if(useNavigator) {
      // clearing ref values from previous page
      navigatorGroupRefs.current = [];

      const { fields } = pages[pageIndex];
      const navigableGroupFields = fields.filter(field => field.type === FIELD_TYPES.GROUP && !!field.label);
      const groups = navigableGroupFields.map(groupField => ({ key: groupField.key, label: groupField.label }));
      setNavigatorGroups(groups);
    }
  }, [pageIndex, useNavigator]);

  const canCancel = !!onCancel;
  const isSinglePage = showAllPages || pages.length === 1;
  const isFirstPage = isSinglePage || pageIndex === 0;
  const isLastPage = isSinglePage || pageIndex === pages.length - 1;
  const showCancel = canCancel && isFirstPage;
  const showSubmit = !!onSubmit && isLastPage;
  const showBack = !isSinglePage && !showCancel;
  const showNext = !isSinglePage && !showSubmit;
  const showInlineActions = isInline && !isReadonly;
  const showNavigator = useNavigator && !!navigatorGroups.length;
  const showFixedActions = !isInline && (showNavigator || !isReadonly);

  const renderActions = () => {
    return (
      <>
        {showCancel &&
          <Button onClick={onCancel} style="ghost_light">
            {labelCancel || "Cancel"}
          </Button>
        }
        {showBack &&
          <Button onClick={prevPage} style="ghost_light" disabled={isFirstPage}>
            Back
          </Button>
        }
        {showNext &&
          <Button onClick={nextPage} style="fill_light" disabled={isLastPage}>
            Next
          </Button>
        }
        {showSubmit &&
          <Button type="submit" style="fill_dark" disabled={activeUploads > 0 || !isFormDataValid || isSubmitting}>
            {isSubmitting ? "Loading..." : labelSubmit}
          </Button>
        }
      </>
    );
  };

  return (
    <>
      <form onSubmit={submitForm}>
        <div className={clsx(styles.form, { [styles.withBackground]: !isInline })}>
          {!isSinglePage &&
            <div className={styles.pageTracker}>
              {pages.map((_, index) => {
                return (
                  <div
                    className={clsx(styles.pageMarker, { [styles.pageMarkerMarked]: index <= pageIndex })}
                    key={index}
                  />
                );
              })}
            </div>
          }
          {!showAllPages &&
            <>
              {pages.length > 1 &&
                <div className={styles.pageCount}>
                  {pageIndex + 1} of {pages.length}
                </div>
              }
              <h1 className="heading2">{pages[pageIndex].title}</h1>
              {!!pages[pageIndex].description &&
                <div className={styles.description}>
                  {pages[pageIndex].description}
                </div>
              }
            </>
          }
          {!!groupFieldsWithDefaultValuesForCurrentPage.length && !isReadonly && (
            <div className={styles.defaultButtons}>
              {groupFieldsWithDefaultValuesForCurrentPage.map((groupField, index) => (
                <Button
                  onClick={() => applyDefaultsForGroup(groupField)}
                  disabled={groupKeysWithDefaultsApplied[groupField.key]}
                  key={index}
                  style="ghost_dark"
                >
                  {groupField.applyDefaultsButtonText}
                </Button>
              ))}
            </div>
          )}
          <div>
            {renderForm()}
          </div>
          {showInlineActions &&
            <div className={styles.inlineActions}>{renderActions()}</div>
          }
          {showFixedActions &&
            <div className={styles.fixedActionsWrapper}>
              {showNavigator &&
                <div className={styles.navigator}>
                  {navigatorGroups.map(group => {
                    return (
                      <div
                        className={styles.navigatorButton}
                        onClick={() => handleGoToGroup(group.key)}
                        key={group.key}
                      >
                        {group.label}
                      </div>
                    );
                  })}
                </div>
              }
              {showNavigator && !isReadonly && <hr className={styles.line} />}
              {!isReadonly &&
                <div className={styles.fixedButtons}>
                  {renderActions()}
                </div>
              }
            </div>
          }
        </div>
      </form>
    </>
  );
};

export default Form;
