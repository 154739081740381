import clsx from "clsx";
import styles from "./styles.module.css";

const Label = ({
  children,
  indicateUnanswered,
  isGroupLabel,
  isReadonly,
  isRequired,
  isUnanswered,
  subLabel,
  useStickyHeader,
}) => {
  let labelClass = styles.label;
  if(isGroupLabel) {
    labelClass = styles.groupHeader;
  }
  if(isGroupLabel && useStickyHeader) {
    labelClass = styles.groupHeaderSticky;
  }

  const flagUnanswered = indicateUnanswered && isUnanswered;
  const flagRequired = isRequired && isUnanswered;

  return (
    children
      ? (
        <>
          <label
            className={clsx(labelClass, { [styles.readonlyLabel]: isReadonly, [styles.errorText]: flagUnanswered })}
          >
            {children}
            {isRequired && <span className={clsx({ [styles.errorText]: flagRequired })}>*</span>}
          </label>
          {subLabel &&
            <div className={styles.subLabel}>{subLabel}</div>
          }
        </>
      )
      : null
  );
};

export default Label;
