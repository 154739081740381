import { getFullName } from "utils/data";
import { EXAM_TYPE_OPTIONS, FIELD_TYPES } from "./consts";
import { getAllDoctorExamTypes } from "./doctor";

export const getChaperoneOptions = (chaperoneRecords) => {
  return chaperoneRecords?.map(chaperone => ({
    value: chaperone._id,
    label: `${chaperone.nameFirst} ${chaperone.nameLast}`
  }));
};

export const getExamOptionsForDoctor = (doctor) => {
  return EXAM_TYPE_OPTIONS.filter(type => !!doctor?.specialties?.find(s => s.examType === type.value));
};

export const getDoctorOptions = (doctorRecords, { withExamDropdown = false } = {}) => {
  return doctorRecords?.map(doctor => {
    const option = {
      value: doctor._id,
      label: `${getFullName(doctor)} (${getAllDoctorExamTypes(doctor).map(t => t.labelShort).join(" | ")})`
    };
    if(withExamDropdown) {
      option.details = [{
        type: FIELD_TYPES.DROPDOWN,
        key: "examType",
        label: "Exam Type",
        required: true,
        hasVariableOptions: true,
        options: getExamOptionsForDoctor(doctor),
      }];
    }
    return option;
  });
};

export const getInsurerOptions = (insurerRecords) => {
  return insurerRecords?.map(insurer => ({
    value: insurer._id,
    label: insurer.name
  }));
};

export const getLocationOptions = (locationRecords) => {
  return locationRecords?.map(location => ({
    value: location._id,
    label: location.name
  }));
};

export const getVendorOptions = (vendorRecords) => {
  return vendorRecords?.map(vendor => ({
    value: vendor._id,
    label: vendor.name
  }));
};

export const optionsMonth = [
  {
    label: "January",
    value: "january"
  },
  {
    label: "February",
    value: "february"
  },
  {
    label: "March",
    value: "march"
  },
  {
    label: "April",
    value: "april"
  },
  {
    label: "May",
    value: "may"
  },
  {
    label: "June",
    value: "june"
  },
  {
    label: "July",
    value: "july"
  },
  {
    label: "August",
    value: "august"
  },
  {
    label: "September",
    value: "september"
  },
  {
    label: "October",
    value: "october"
  },
  {
    label: "November",
    value: "november"
  },
  {
    label: "December",
    value: "december"
  },
];
